import '../styles/globals.css'

import type { AppProps } from 'next/app'
import Head from 'next/head'
import Script from 'next/script'
import { useEffect } from 'react'

function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    const anyWindow = window as any
    anyWindow.dataLayer = anyWindow.dataLayer || []
    function gtag() {
      anyWindow.dataLayer.push(arguments)
    }
    anyWindow.gtag = gtag
    anyWindow.gtag('js', new Date())
    anyWindow.gtag('config', 'G-QBXHL4M79W')
  }, [])

  return (
    <>
      <Head>
        {pageProps.title ? <title>{pageProps.title}</title> : null}
        {pageProps.description ? (
          <meta name="description" content={pageProps.description} />
        ) : null}
        <link
          rel="icon"
          href="/assets/images/favicon.png"
          type="image/x-icon"
        />
      </Head>
      <Component {...pageProps} />
      <Script src="https://www.googletagmanager.com/gtag/js?id=G-QBXHL4M79W" />
    </>
  )
}

export default MyApp
